import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Typing from './Components/Typging'
import LastMile from './Components/Pages/LastMile.js'
// import LastMileDriver from './Components/Pages/LastMileDriver'
import LastMileLogin from './Components/Pages/LastMileLogin.js'
import LastMileVerfication from './Components/Pages/Verification.js'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
   {
    path: "/Typing",
    element: <Typing />,
  },  {
    path: "/lastMile",
    element: <LastMile />,
  },
  {
    path: "/LastMileLogin",
    element: <LastMileLogin />,
  },
  {
    path: "/verification",
    element: <LastMileVerfication />,
  },
]);
ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

