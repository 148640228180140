import React, { useState } from 'react'
import samimazimi from '../assets/SamimAzimi.jpeg'
// import CVPDF from '../data/SamimAzimiCV.pdf'
import { HiMenuAlt3 } from 'react-icons/hi'
import HomeSvg from '../assets/svgIcons/HomeSvg.js'
import ContactSvg from '../assets/svgIcons/ContactSvg.js'
import WorkSvg from '../assets/svgIcons/workSvg.js'
// import InterestSvg from '../assets/svgIcons/interestSvg.js'
// import DownloadSvg from '../assets/svgIcons/downloadSvg.js'
// import SkillSvg from '../assets/svgIcons/skillsSvg.js'
import SA from '../assets/svgIcons/SAlogoSvg.js'
function Nav() {
	const [isOpen, setIsOpen] = useState(false)
	const [logo, setLogo] = useState(true)


	const toggleMenu = () => {
		setIsOpen(!isOpen)
	}
	const renderClasses = () => {

		let cleasses = "navigation";
		if (isOpen) {
			return cleasses += " active";
		}

		return cleasses;



	};
	return (
		<nav >
			<div onMouseLeave={() => setLogo(true)} onMouseEnter={() => setLogo(false)} className="SamimAzimiPhoto">
				{logo ? <SA />: <img src={samimazimi} alt={samimazimi.toLocaleUpperCase()} /> }
			</div>
			<div onClick={toggleMenu} className="MenuIcon">
				<HiMenuAlt3 />
			</div>

			<div className={renderClasses()}>
				<div className="navItems">
					<a aria-label="Home page" href="#home">
						<HomeSvg />
						<p>Home</p></a>
				</div>
				<div className="navItems">
					<a aria-label="Contact Page" href="#contact">
						<ContactSvg />
						<p>Contact Me</p></a>
				</div>
				<div className="navItems">
					<a aria-label="Selected Works" href="#work">
						<WorkSvg />
						<p>Work</p></a>
				</div>
				{/* <div aria-label="Skill Page" className="navItems">
					<a href="#skill">
						<SkillSvg />
						<p>Skills</p></a>
				</div> */}
				{/* <div className="navItems">
					<a aria-label="Interest Page" href="/Typing">
						<InterestSvg />
						<p>Typing</p></a>
				</div> */}
				{/* <div className="navItems">
					<a aria-label="Download Samim Azimi CV" href={CVPDF} download="Samim Azimi Resume">
						<DownloadSvg />
						<p>CV</p></a>
				</div> */}
			</div>



		</nav>
	)
}

export default Nav
