import React, { useState } from 'react'
import { MdOutlineMail } from 'react-icons/md';
import { BsPerson, BsGithub, BsLinkedin } from 'react-icons/bs';
import { GrSend } from 'react-icons/gr';
import { toast } from "react-toastify";
import emailjs from "emailjs-com";
import ScrumBoard from '../assets/svgIcons/ScrumBoard.js'
function ContactMe() {
    const [person, setPerson] = useState({
        fname: "",
        email: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPerson({ ...person, [name]: value });
    };

    const handlesubmit = (e) => {
        e.preventDefault();

        if (!person.fname) return toast.info("Please Enter Your Name")
        if (!person.email) return toast.info("Please Enter Your Email")
        if (!person.subject) return toast.info("Please Enter a Passport")
        if (!person.message) return toast.info("Please Enter Your Message")


        emailjs
            .sendForm(
                "service_f95aveh",
                "template_uqyq55w",
                e.target,
                "s7dauiIwdDY54Pvh8"
            )
            .then(
                (result) => {
                    toast.info("Thanks, Your Message Has been Sent Successfully");
                    setPerson({
                        fname: "",
                        email: "",
                        subject: "",
                        message: "",
                    });
                },
                (error) => {
                    console.log(error)
                    toast.dark("Something Went Wrong");
                }
            );

    }
    return (
        <section id="contact"  >
            <div className="scrumSvg">
                <ScrumBoard />
            </div>
            <aside>
                <h1 className="contactMeH1">want to resolve an issue  from your backlog ?</h1>
            </aside>
            <form id="contactForm" onSubmit={handlesubmit} >
                <h3>Contact Me </h3>
                <div className="Groups">
                    <div>
                        <label htmlFor="fname" ><BsPerson />
                        </label>

                        <input value={person.fname}
                            onChange={handleChange} tabIndex="1" type="text" placeholder="Full Name" name="fname" />
                    </div>
                    <div>
                        <label htmlFor="email" ><MdOutlineMail />
                        </label>
                        <input tabIndex="2" value={person.email}
                            onChange={handleChange} placeholder="Your Email Address" type="email" name="email" />
                    </div>
                </div>
                <div>
                    <input tabIndex="3" value={person.subject}
                        onChange={handleChange} placeholder="Subject" type="text" name="subject" />
                </div>
                <textarea value={person.message}
                    onChange={handleChange} id="one" tabIndex="4" name="message" placeholder="Type Your Message Here" />

                <button type="submit"> <p>Hit Me</p><div><GrSend /></div></button>
            </form>

            <footer>
                <h1><a href="https://github.com/SamimAzimi" aria-label="My GitHub Repository" rel="noreferrer" target="_blank"><BsGithub /></a> <a href="https://af.linkedin.com/in/samim-azimi-4023a8b6" aria-label="My Linkden Page" rel="noreferrer" target="_blank"><BsLinkedin /></a></h1>
            </footer>
        </section>
    )
}

export default ContactMe
