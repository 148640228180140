import React,{useState} from 'react'
import '../../style/LastMile/lastMile.css'
import ReactMapGL, {  NavigationControl} from "react-map-gl";

export default function LastMile() {

    const [viewport, setViewport] = useState({
        width: window.innerWidth-220,
        height: window.innerHeight,
        latitude: 37.21,
        longitude: 67.28,
        zoom: 10,

    });

    const settings = {
        dragPan: true,
        dragRotate: false,
        scrollZoom: false,
        touchZoom: false,
        touchRotate: false,
        keyboard: false,
        doubleClickZoom: false,

    };
  return (

    <div className='mainPageLastMile'>
    <div className='menu'> 
      <ul>
        <li>Travel</li>
        <li>Become An Inner City Driver</li>
        <li>Become Logistic & Local Driver</li>
        <li>Small Goods</li>
        <li>Large Goods</li>
      </ul>
    </div>
    <div className='map'>
        <ReactMapGL
                    {...viewport}
                    {...settings}
                    container="mapbox"
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    mapboxApiAccessToken={
                        "pk.eyJ1IjoiaXRzc2N0byIsImEiOiJja3AyZXF3YzkweDV6MnZ0ZWlybDltZjhhIn0.Q6-NrYiizpfKP11JRtMbrA"
                    }

                    onViewportChange={(viewport) => setViewport(viewport)}
                > 
                    <NavigationControl/>
        </ReactMapGL>
    </div>
    </div> 
  )
}
