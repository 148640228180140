import React from 'react'

function Home() {
    return (
        <section id="home" role="main">
            <div className='grouper'>
            <h1>I'm Sam</h1>

            <h1 className="headingtwo"> Welcome to My portofolio</h1>

            <div className="wrapper">
                <div className="staticText">I'm</div>
                <ul className="animatedText">

                    <li><span>Web Designer & Developer</span></li>
                    <li><span>Database Administrator</span></li>
                    <li><span>Arduino Hobbist (IoT)</span></li>
                    <li><span>System Administrator</span></li>
                </ul>
            </div>
            </div>
        </section >
    )
}

export default Home
