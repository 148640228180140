import React, {useState} from 'react'
import app from '../../firebase.config.js'
import {getDatabase,set,ref}from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import UZ from 'country-flag-icons/react/3x2/UZ'
import '../../style/LastMile/lastMileLogin.css'

function LastMileLogin() {
  const [user,setUser] = useState({
    name:"",
    tel:""
  })
  const navigate = useNavigate()
  const handleChange=(e)=>{
    const name = e.target.name
    const value = e.target.value 
    setUser({...user, [name]:value})

  }
    const writeUserDate=(data)=> {
           const database = getDatabase(app);
            set(ref(database,'users/' + data.tel),data);
    }
  const handleSubmit =(e)=>{
    e.preventDefault()
    if(user.name ===""){
      toast.info("Enter Your Name")
      
    }else if (user.tel===""){
      toast.info("Enter Your Phone Number")
    }
    if(user){
      writeUserDate(user)
      navigate("/verification")
    }
  }
  return (
    <div className='containers'>
    <form onSubmit={handleSubmit} className="lastMileLoginForm">
        <h1>Last Mile Login</h1>
        <label for="name">Name</label>
        <input type='text' name='name' value={user.name} required onChange={handleChange} 
        placeholder="Enter Your Name"/>
        <label for="tel">Cellphone</label>
        <div className='grpTel'>
          <UZ title="Uzbekistan" className='flag'/><input type='text' value="+998" disabled className='countryCodeInput' />
        <input type="tel" name="tel" value={user.tel} required onChange={handleChange}
        placeholder="Enter Your Cellphone Number"/>
        </div>
        <div className='btngrp'>
        <button>Submit</button>
        <button> Cancel</button>
        </div>
    </form>
    <ToastContainer />
    </div>
  )
}

export default LastMileLogin