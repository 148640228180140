// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBCZPNzERj8o-elx2QYkfEAyUmY1YkaTPk",
  authDomain: "lastmile-a4f67.firebaseapp.com",
  databaseURL: "https://lastmile-a4f67-default-rtdb.firebaseio.com",
  projectId: "lastmile-a4f67",
  storageBucket: "lastmile-a4f67.appspot.com",
  messagingSenderId: "451722536707",
  appId: "1:451722536707:web:44eaf2144f09843678ab36",
  measurementId: "G-TNXGVDKYJY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app 