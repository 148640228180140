
import React, { useEffect, useState, useRef } from 'react'
import { BsFillHeartFill,BsTrophy } from 'react-icons/bs';

import '../style/typing.css'
function Typing() {
    const [pressed,setPressed]= useState()
    const [lives,setLives] =useState(5)
    const [score,setScore] = useState(0)
    const [promptWord,setPromptWords] = useState()
    const alphabet = "абвгдежзийклмнопстуфхцчшщъыьэюя"
    const inputType = useRef()

    const generateRandomWords =()=>{
        const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]
        setPromptWords(randomCharacter.toUpperCase())
       
        const keyboard_key = document.querySelectorAll('.keyboard__key')
        keyboard_key.forEach(function(item,index,arr){
            if(randomCharacter.toUpperCase()===item.textContent.toUpperCase()){

                item.classList.add("promptshowinKeyboard")  
                console.log(item)
            }
            if(randomCharacter.toUpperCase()!==item.textContent.toUpperCase()){

                item.classList.remove('promptshowinKeyboard')
            }
        })

        
    }
    useEffect(() => {
        generateRandomWords()
                
    },[])
    
    const handleChange=(e)=>{
        e.preventDefault();
        const keypress = String(e.target.value).toUpperCase();

        
        setPressed(keypress)
        setTimeout(() => {
            if(keypress===promptWord){
                    setScore(score + 1)
                   
                    generateRandomWords()
                    console.log(e)
                    setTimeout(()=>{
                        setPressed('')
                     
                    },10)
                }
                else if (lives>0) {    
                    setLives(lives -1 )
                    setPressed('')

                   
            }
            else if(lives===0){
                setPressed('Restart')
               
            }
        }, 100);
        
        
        
    }   
    
    const handleRestart =()=>{
        setLives(5)
        setScore(0)
        setPressed('')
        inputType.current.focus();
    }
  
    return (
        <section lang='ru' className='Typing'>
            <div className='notes'>
                <h4>Hello World!, Typing Practice </h4>
            </div>
            <div className='wordAppear'>
                <div className='prompt'>
                        <h1>{promptWord}</h1>
                </div>
               <div class="keyboard"  id="60-percent-keyboard">
                        {/* <!-- Start Row 2 --> */}
                        <div class="keyboard__key keyboard__key--1-5u keyboard__key--modifier" >Tab</div>
                        <div class="keyboard__key" data-key-text="">й</div>
                        <div class="keyboard__key" data-key-text="">ц</div>
                        <div class="keyboard__key" data-key-text="">у</div>
                        <div class="keyboard__key" data-key-text="">к</div>
                        <div class="keyboard__key" data-key-text="">е</div>
                        <div class="keyboard__key" data-key-text="">н</div>
                        <div class="keyboard__key" data-key-text="">г</div>
                        <div class="keyboard__key" data-key-text="">ш</div>
                        <div class="keyboard__key" data-key-text="">щ</div>
                        <div class="keyboard__key" data-key-text="">з</div>
                        <div class="keyboard__key" data-key-text="">х</div>
                        <div class="keyboard__key no_mobile" data-key-text="">ъ</div>
                        <div class="keyboard__key keyboard__key--1-5u keyboard__key--modifier" data-key-text="">\</div>
                        {/* <!-- End Row 2 -->

                        <!-- Start Row 3 --> */}
                        <div class="keyboard__key keyboard__key--1-75u keyboard__key--modifier" data-key-text="">Caps Locks</div>
                        <div class="keyboard__key" data-key-text="">ф</div>
                        <div class="keyboard__key" data-key-text="">ы</div>
                        <div class="keyboard__key" data-key-text="">в</div>
                        <div class="keyboard__key" data-key-text="">а</div>
                        <div class="keyboard__key" data-key-text="">п</div>
                        <div class="keyboard__key" data-key-text="">р</div>
                        <div class="keyboard__key" data-key-text="">о</div>
                        <div class="keyboard__key" data-key-text="">л</div>
                        <div class="keyboard__key" data-key-text="">д</div>
                        <div class="keyboard__key" data-key-text="">ж</div>
                        <div class="keyboard__key" data-key-text="">э</div>
                        <div class="keyboard__key keyboard__key--2-25u keyboard__key--modifier keyboard__key--enter" data-key-text="">enter</div>
                        {/* <!-- End Row 3 -->

                        <!-- Start Row 4 --> */}
                        <div class="keyboard__key keyboard__key--2-25u keyboard__key_Shift keyboard__key--modifier" data-key-text="">shift</div>
                        <div class="keyboard__key" data-key-text="">я</div>
                        <div class="keyboard__key" data-key-text="">ч</div>
                        <div class="keyboard__key" data-key-text="">с</div>
                        <div class="keyboard__key" data-key-text="">м</div>
                        <div class="keyboard__key" data-key-text="">и</div>
                        <div class="keyboard__key" data-key-text="">т</div>
                        <div class="keyboard__key" data-key-text="">ь</div>
                        <div class="keyboard__key" data-key-text="">б</div>
                        <div class="keyboard__key" data-key-text="">ю</div>
                        <div class="keyboard__key no_mobile" data-key-text="">.</div>
                        <div class="keyboard__key keyboard__key--2-75u keyboard__key_Shift keyboard__key--modifier" data-key-text="">shift</div>
                </div>
            </div>
           
            <div className='status'>
                    <div className='score'>
                    
                    <h2>{score}</h2>
                    <h2 className='scoreIcon'><BsTrophy /></h2>
                    </div>
                     <div className='InputBox'>
            <input ref={inputType}  value={pressed} maxLength="1" className="typeInput" onChange={handleChange}
                             autoFocus={true} autoComplete="off" tabIndex="1" type="text" placeholder="Type" name="type" />
            </div>
                    <div className='lives'>
                    {lives ? [...Array(lives)].map((lives,index)=><h2 key={index}><BsFillHeartFill/></h2>) : (
                        <div>
                            <button className='restartBtn' onClick={handleRestart}>Restart</button>
                            </div>
                    )
                    }
                    </div>

            </div>
        </section>
    )
}

export default Typing