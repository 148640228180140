import React from 'react'
import { BsGlobe2 } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go'

function Clients(props) {

    const { info } = props;
    const { name, address, website, logo, technologUsed } = info


    return (

        <div className="pinsContainer">
            <div className="websiteLogo">
                <div className="websiteImageContainer">
                    <img src={logo} alt={name} />
                </div>
            </div>
            <h1><BsGlobe2 /> <a href={website} rel="noreferrer" target="_blank">Visit Site</a></h1>
            <h1><GoLocation />{address}</h1>
            <details>
                <summary>Technologies Used</summary>
                <ul>{Object.entries(technologUsed).map(([key, value]) => {
                    return (
                        <li>{value}</li>
                    )
                })}</ul>
            </details>
        </div>
    )
}

export default Clients
