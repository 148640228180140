import React, { useState } from 'react'
import MapHeader from './MapHeader.js';
import ReactMapGL, { Source, NavigationControl, Popup } from "react-map-gl";
import Clients from './Clients.js'
import 'mapbox-gl/dist/mapbox-gl.css';
import clientsData from "../data/clients.json";
import Pins from './pins.js'
const geogson = {
    type: 'vector',
    url: 'mapbox://styles/itsscto/ckwsha9fh1vo715nqzwpc1zan'
}
const attributionStyle = {

    left: 20,
    bottom: 160
};

function WorkExperience() {
    const [popupInfo, setPopupInfo] = useState(null);
    const [viewport, setViewport] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        latitude: 90,
        longitude: -122.45,
        zoom: 0,

    });




    const settings = {
        dragPan: true,
        dragRotate: false,
        scrollZoom: false,
        touchZoom: true,
        touchRotate: false,
        keyboard: false,
        doubleClickZoom: true,

    };
    const handleClosePopup =()=>{
        if(popupInfo){
            setPopupInfo(false)
        }
    }

    return (
        <section id="work">
            <div className="mapBox">
                <ReactMapGL
                    {...viewport}
                    {...settings}
                    onClick={handleClosePopup}
                    container="mapbox"
                    mapStyle="mapbox://styles/itsscto/ckwsha9fh1vo715nqzwpc1zan"
                    mapboxApiAccessToken={
                        "pk.eyJ1IjoiaXRzc2N0byIsImEiOiJja3AyZXF3YzkweDV6MnZ0ZWlybDltZjhhIn0.Q6-NrYiizpfKP11JRtMbrA"
                    }

                    onViewportChange={(viewport) => setViewport(viewport)}
                >
                    <Pins data={clientsData} onClick={setPopupInfo} />

                    <Source id="mydata" type="vector" data={geogson}>
                        {/* <Layer sourceId="mydata"  {...layersettings} /> */}
                    </Source>
                    {popupInfo && <Popup
                        tipSize={10}
                        anchor="top"
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        closeOnClick={false}
                        onClose={setPopupInfo}

                    >
                        <Clients info={popupInfo} />
                    </Popup>}
                    <NavigationControl style={attributionStyle} />
                    <MapHeader />
                </ReactMapGL>

            </div>
        </section>
    )
}

export default WorkExperience
