import React from 'react'

function MapHeader() {
    return (
        <div className="mapHeader">
            <h1>Selected Works</h1>
        </div>
    )
}

export default React.memo(MapHeader);
