import React from 'react'

function ScrumBoard() {
    return (
        <svg width="822" height="580" viewBox="0 0 822 580" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="undraw_scrum_board_re_wk7v 1" clip-path="url(#clip0_2_99)">
                <g id="circleUpper">
                    <path id="Vector" d="M182.076 58H129.676C124.639 58.0046 119.808 60.0078 116.246 63.57C112.684 67.1322 110.681 71.9623 110.676 77V133.81C110.006 133.77 109.336 133.71 108.676 133.63C96.2623 132.324 84.46 127.576 74.6001 119.921C64.7402 112.266 57.2148 102.009 52.8724 90.3065C48.53 78.6036 47.5432 65.9204 50.0234 53.6868C52.5036 41.4532 58.352 30.1557 66.9093 21.068C75.4667 11.9804 86.3926 5.46408 98.4552 2.25374C110.518 -0.9566 123.237 -0.733287 135.18 2.89852C147.122 6.53032 157.813 13.4262 166.046 22.8086C174.279 32.191 179.727 43.6869 181.776 56C181.886 56.66 181.986 57.33 182.076 58Z" fill="#F2F2F2" />
                </g>
                <g id="board">
                    <path id="Vector_2" d="M800.676 56H129.676C124.109 56.0061 118.771 58.2205 114.834 62.1575C110.897 66.0944 108.682 71.4323 108.676 77V431C108.682 436.568 110.897 441.906 114.834 445.842C118.771 449.779 124.109 451.994 129.676 452H800.676C806.244 451.994 811.582 449.779 815.519 445.842C819.456 441.906 821.67 436.568 821.676 431V77C821.67 71.4323 819.456 66.0944 815.519 62.1575C811.582 58.2205 806.244 56.0061 800.676 56ZM819.676 431C819.672 436.038 817.668 440.868 814.106 444.43C810.544 447.992 805.714 449.995 800.676 450H129.676C124.639 449.995 119.808 447.992 116.246 444.43C112.684 440.868 110.681 436.038 110.676 431V77C110.681 71.9623 112.684 67.1322 116.246 63.57C119.808 60.0078 124.639 58.0046 129.676 58H800.676C805.714 58.0046 810.544 60.0078 814.106 63.57C817.668 67.1322 819.672 71.9623 819.676 77V431Z" />
                </g>
                <g id="sprintLines">
                    <path id="Vector_3" d="M767.676 113.37H162.676C162.411 113.37 162.157 113.264 161.969 113.077C161.782 112.889 161.676 112.635 161.676 112.37C161.676 112.104 161.782 111.85 161.969 111.663C162.157 111.475 162.411 111.37 162.676 111.37H767.676C767.941 111.37 768.196 111.475 768.383 111.663C768.571 111.85 768.676 112.104 768.676 112.37C768.676 112.635 768.571 112.889 768.383 113.077C768.196 113.264 767.941 113.37 767.676 113.37Z" />
                    <path id="Vector_4" d="M333.676 409C333.411 409 333.157 408.895 332.969 408.707C332.782 408.52 332.676 408.265 332.676 408V79C332.676 78.7348 332.782 78.4804 332.969 78.2929C333.157 78.1054 333.411 78 333.676 78C333.941 78 334.196 78.1054 334.383 78.2929C334.571 78.4804 334.676 78.7348 334.676 79V408C334.676 408.265 334.571 408.52 334.383 408.707C334.196 408.895 333.941 409 333.676 409V409Z" />
                    <path id="Vector_5" d="M484.676 409C484.411 409 484.157 408.895 483.969 408.707C483.782 408.52 483.676 408.265 483.676 408V79C483.676 78.7348 483.782 78.4804 483.969 78.2929C484.157 78.1054 484.411 78 484.676 78C484.941 78 485.196 78.1054 485.383 78.2929C485.571 78.4804 485.676 78.7348 485.676 79V408C485.676 408.265 485.571 408.52 485.383 408.707C485.196 408.895 484.941 409 484.676 409V409Z" />
                    <path id="Vector_6" d="M635.676 409C635.411 409 635.157 408.895 634.969 408.707C634.782 408.52 634.676 408.265 634.676 408V79C634.676 78.7348 634.782 78.4804 634.969 78.2929C635.157 78.1054 635.411 78 635.676 78C635.941 78 636.196 78.1054 636.383 78.2929C636.571 78.4804 636.676 78.7348 636.676 79V408C636.676 408.265 636.571 408.52 636.383 408.707C636.196 408.895 635.941 409 635.676 409V409Z" />
                </g>
                <path id="Vector_7" d="M236.592 216.587H200.052C198.859 216.586 197.715 216.112 196.871 215.268C196.028 214.424 195.553 213.28 195.552 212.087V168.968C195.553 167.775 196.028 166.631 196.871 165.787C197.715 164.944 198.859 164.469 200.052 164.468H236.592C237.785 164.469 238.929 164.944 239.772 165.787C240.616 166.631 241.091 167.775 241.092 168.968V212.087C241.091 213.28 240.616 214.424 239.772 215.268C238.929 216.112 237.785 216.586 236.592 216.587V216.587Z" fill="#6C63FF" />
                <g id="firstIssue">
                    <path id="Vector_8" d="M288.592 339.587H252.052C250.859 339.586 249.715 339.112 248.871 338.268C248.028 337.424 247.553 336.28 247.552 335.087V291.968C247.553 290.775 248.028 289.631 248.871 288.787C249.715 287.944 250.859 287.469 252.052 287.468H288.592C289.785 287.469 290.929 287.944 291.772 288.787C292.616 289.631 293.091 290.775 293.092 291.968V335.087C293.091 336.28 292.616 337.424 291.772 338.268C290.929 339.112 289.785 339.586 288.592 339.587V339.587Z" />
                </g>
                <g id="thirdIssue">
                    <path id="Vector_9" d="M406.592 242.587H370.052C368.859 242.586 367.715 242.112 366.872 241.268C366.028 240.424 365.553 239.28 365.552 238.087V194.968C365.553 193.775 366.028 192.631 366.872 191.787C367.715 190.944 368.859 190.469 370.052 190.468H406.592C407.785 190.469 408.929 190.944 409.773 191.787C410.616 192.631 411.091 193.775 411.092 194.968V238.087C411.091 239.28 410.616 240.424 409.773 241.268C408.929 242.112 407.785 242.586 406.592 242.587V242.587Z" fill="#6C63FF" />
                </g>
                <path id="Vector_10" d="M700.592 195.587H664.052C662.859 195.586 661.715 195.112 660.872 194.268C660.028 193.424 659.553 192.28 659.552 191.087V147.968C659.553 146.775 660.028 145.631 660.872 144.787C661.715 143.944 662.859 143.469 664.052 143.468H700.592C701.785 143.469 702.929 143.944 703.773 144.787C704.616 145.631 705.091 146.775 705.092 147.968V191.087C705.091 192.28 704.616 193.424 703.773 194.268C702.929 195.112 701.785 195.586 700.592 195.587V195.587Z" fill="#6C63FF" />
                <g id="hiddenIssueTwo">
                    <path id="Vector_11" d="M765.592 207.587H729.052C727.859 207.586 726.715 207.112 725.872 206.268C725.028 205.424 724.553 204.28 724.552 203.087V159.968C724.553 158.775 725.028 157.631 725.872 156.787C726.715 155.944 727.859 155.469 729.052 155.468H765.592C766.785 155.469 767.929 155.944 768.773 156.787C769.616 157.631 770.091 158.775 770.092 159.968V203.087C770.091 204.28 769.616 205.424 768.773 206.268C767.929 207.112 766.785 207.586 765.592 207.587Z" />
                </g>
                <g id="hiddenIssueOne">
                    <path id="Vector_12" d="M736.592 284.587H700.052C698.859 284.586 697.715 284.112 696.872 283.268C696.028 282.424 695.553 281.28 695.552 280.087V236.968C695.553 235.775 696.028 234.631 696.872 233.787C697.715 232.944 698.859 232.469 700.052 232.468H736.592C737.785 232.469 738.929 232.944 739.773 233.787C740.616 234.631 741.091 235.775 741.092 236.968V280.087C741.091 281.28 740.616 282.424 739.773 283.268C738.929 284.112 737.785 284.586 736.592 284.587Z" />
                </g>
                <g id="secondIssue">
                    <path id="Vector_13" d="M559.592 195.587H523.052C521.859 195.586 520.715 195.112 519.872 194.268C519.028 193.424 518.553 192.28 518.552 191.087V147.968C518.553 146.775 519.028 145.631 519.872 144.787C520.715 143.944 521.859 143.469 523.052 143.468H559.592C560.785 143.469 561.929 143.944 562.773 144.787C563.616 145.631 564.091 146.775 564.092 147.968V191.087C564.091 192.28 563.616 193.424 562.773 194.268C561.929 195.112 560.785 195.586 559.592 195.587V195.587Z" />
                    <path id="Vector_14" d="M612.592 268.587H576.052C574.859 268.586 573.715 268.112 572.872 267.268C572.028 266.424 571.553 265.28 571.552 264.087V220.968C571.553 219.775 572.028 218.631 572.872 217.787C573.715 216.944 574.859 216.469 576.052 216.468H612.592C613.785 216.469 614.929 216.944 615.773 217.787C616.616 218.631 617.091 219.775 617.092 220.968V264.087C617.091 265.28 616.616 266.424 615.773 267.268C614.929 268.112 613.785 268.586 612.592 268.587Z" />
                </g>
                <path id="Vector_15" d="M541.592 284.587H505.052C503.859 284.586 502.715 284.112 501.872 283.268C501.028 282.424 500.553 281.28 500.552 280.087V236.968C500.553 235.775 501.028 234.631 501.872 233.787C502.715 232.944 503.859 232.469 505.052 232.468H541.592C542.785 232.469 543.929 232.944 544.773 233.787C545.616 234.631 546.091 235.775 546.092 236.968V280.087C546.091 281.28 545.616 282.424 544.773 283.268C543.929 284.112 542.785 284.586 541.592 284.587V284.587Z" fill="#E6E6E6" />
                <g id="hiddenIssueFour">
                    <path id="Vector_16" d="M388.592 352.587H352.052C350.859 352.586 349.715 352.112 348.872 351.268C348.028 350.424 347.553 349.28 347.552 348.087V304.968C347.553 303.775 348.028 302.631 348.872 301.787C349.715 300.944 350.859 300.469 352.052 300.468H388.592C389.785 300.469 390.929 300.944 391.773 301.787C392.616 302.631 393.091 303.775 393.092 304.968V348.087C393.091 349.28 392.616 350.424 391.773 351.268C390.929 352.112 389.785 352.586 388.592 352.587Z" />
                </g>
                <path id="Vector_17" d="M203.592 295.587H167.052C165.859 295.586 164.715 295.112 163.871 294.268C163.028 293.424 162.553 292.28 162.552 291.087V247.968C162.553 246.775 163.028 245.631 163.871 244.787C164.715 243.944 165.859 243.469 167.052 243.468H203.592C204.785 243.469 205.929 243.944 206.772 244.787C207.616 245.631 208.091 246.775 208.092 247.968V291.087C208.091 292.28 207.616 293.424 206.772 294.268C205.929 295.112 204.785 295.586 203.592 295.587V295.587Z" fill="#FF6584" />
                <g id="hiddenIssueThree">
                    <path id="Vector_18" d="M306.592 216.587H270.052C268.859 216.586 267.715 216.112 266.871 215.268C266.028 214.424 265.553 213.28 265.552 212.087V168.968C265.553 167.775 266.028 166.631 266.871 165.787C267.715 164.944 268.859 164.469 270.052 164.468H306.592C307.785 164.469 308.929 164.944 309.772 165.787C310.616 166.631 311.091 167.775 311.092 168.968V212.087C311.091 213.28 310.616 214.424 309.772 215.268C308.929 216.112 307.785 216.586 306.592 216.587V216.587Z" />
                </g>
                <path id="Vector_19" d="M276.676 97H219.676C218.615 97 217.598 96.5786 216.848 95.8284C216.098 95.0783 215.676 94.0609 215.676 93C215.676 91.9391 216.098 90.9217 216.848 90.1716C217.598 89.4214 218.615 89 219.676 89H276.676C277.737 89 278.755 89.4214 279.505 90.1716C280.255 90.9217 280.676 91.9391 280.676 93C280.676 94.0609 280.255 95.0783 279.505 95.8284C278.755 96.5786 277.737 97 276.676 97Z" fill="#CCCCCC" />
                <path id="Vector_20" d="M437.676 97H380.676C379.615 97 378.598 96.5786 377.848 95.8284C377.098 95.0783 376.676 94.0609 376.676 93C376.676 91.9391 377.098 90.9217 377.848 90.1716C378.598 89.4214 379.615 89 380.676 89H437.676C438.737 89 439.755 89.4214 440.505 90.1716C441.255 90.9217 441.676 91.9391 441.676 93C441.676 94.0609 441.255 95.0783 440.505 95.8284C439.755 96.5786 438.737 97 437.676 97Z" fill="#CCCCCC" />
                <path id="Vector_21" d="M588.676 97H531.676C530.615 97 529.598 96.5786 528.848 95.8284C528.098 95.0783 527.676 94.0609 527.676 93C527.676 91.9391 528.098 90.9217 528.848 90.1716C529.598 89.4214 530.615 89 531.676 89H588.676C589.737 89 590.755 89.4214 591.505 90.1716C592.255 90.9217 592.676 91.9391 592.676 93C592.676 94.0609 592.255 95.0783 591.505 95.8284C590.755 96.5786 589.737 97 588.676 97Z" fill="#CCCCCC" />
                <path id="Vector_22" d="M729.676 97H672.676C671.615 97 670.598 96.5786 669.848 95.8284C669.098 95.0783 668.676 94.0609 668.676 93C668.676 91.9391 669.098 90.9217 669.848 90.1716C670.598 89.4214 671.615 89 672.676 89H729.676C730.737 89 731.755 89.4214 732.505 90.1716C733.255 90.9217 733.676 91.9391 733.676 93C733.676 94.0609 733.255 95.0783 732.505 95.8284C731.755 96.5786 730.737 97 729.676 97Z" fill="#CCCCCC" />
                <g id="womenUpperBody">
                    <g id="women">
                        <path id="Vector_23" d="M184.404 566.253H196.664L202.496 518.965H184.402L184.404 566.253Z" fill="#FFB8B8" />
                        <path id="Vector_24" d="M220.308 577.636L181.777 577.637L181.777 562.75L205.42 562.749C209.369 562.749 213.155 564.317 215.947 567.109C218.739 569.901 220.308 573.687 220.308 577.636V577.636L220.308 577.636Z" fill="#2F2E41" />
                        <path id="Vector_25" d="M100.345 557.401L112.193 560.555L129.994 516.359L112.509 511.705L100.345 557.401Z" fill="#FFB8B8" />
                        <path id="Vector_26" d="M132.113 577.639L94.8788 567.729L98.708 553.343L121.556 559.424C125.372 560.44 128.627 562.929 130.607 566.345C132.587 569.761 133.129 573.824 132.113 577.639V577.639V577.639Z" fill="#2F2E41" />
                        <path id="Vector_27" d="M199.961 557.3H181.254C180.072 557.304 178.932 556.866 178.057 556.071C177.182 555.277 176.637 554.183 176.528 553.007L165.171 435.075C165.088 434.21 164.707 433.401 164.093 432.786C163.479 432.171 162.67 431.789 161.805 431.704C160.94 431.62 160.073 431.838 159.351 432.323C158.63 432.808 158.099 433.528 157.85 434.36L124.877 544.56C124.528 545.731 123.74 546.722 122.679 547.327C121.617 547.932 120.362 548.103 119.177 547.806L101.774 543.454C101.166 543.302 100.595 543.032 100.092 542.658C99.5899 542.285 99.1663 541.816 98.8459 541.278C98.5255 540.74 98.3146 540.144 98.2254 539.524C98.1363 538.905 98.1706 538.273 98.3264 537.667C98.6105 536.557 126.737 426.639 126.983 425.509C133.355 378.541 140.98 365.955 145.076 359.194C145.415 358.635 145.726 358.12 146.007 357.634C146.358 357.025 148.519 350.989 149.89 347.069C150.068 346.432 150.377 345.838 150.796 345.325C151.215 344.813 151.736 344.393 152.325 344.092C169.536 335.29 189.673 341.383 195.435 343.46C196.39 343.796 197.21 344.433 197.773 345.275C215.057 371.347 206.575 522.767 204.699 552.856C204.621 554.06 204.088 555.189 203.208 556.014C202.328 556.839 201.167 557.299 199.961 557.3V557.3Z" fill="#2F2E41" />
                        <path id="Vector_28" d="M149.009 389.891C147.546 389.748 146.132 389.286 144.867 388.537C143.602 387.788 142.517 386.771 141.687 385.557C140.858 384.343 140.304 382.963 140.066 381.512C139.828 380.061 139.911 378.576 140.309 377.161L114.676 353.3L128.229 349.862L153.435 370.305C155.768 371.132 157.717 372.785 158.912 374.952C160.108 377.119 160.467 379.65 159.921 382.064C159.376 384.478 157.964 386.609 155.953 388.051C153.941 389.494 151.471 390.149 149.009 389.891V389.891Z" fill="#FFB8B8" />
                        <path id="Vector_29" d="M149.082 351.838L148.905 351.667C148.574 351.347 115.85 319.287 120.085 289.643C121.896 276.963 132.155 268.861 149.752 266.212C156.217 265.252 162.812 266.657 168.323 270.17C173.835 273.683 177.893 279.067 179.752 285.333L197.152 344.817L149.082 351.838Z" fill="#CCCCCC" />
                        <path id="Vector_30" d="M132.333 374.641L109.014 351.47C107.42 349.876 106.262 347.899 105.653 345.729C105.044 343.559 105.003 341.269 105.535 339.078L116.823 293.159C117.161 291.783 117.778 290.491 118.636 289.363C119.493 288.235 120.573 287.296 121.809 286.602C123.044 285.908 124.409 285.475 125.819 285.33C127.228 285.185 128.652 285.331 130.003 285.759C132.578 286.589 134.729 288.388 135.999 290.776C137.27 293.164 137.562 295.953 136.812 298.553L124.928 338.949L144.146 359.586L132.333 374.641Z" fill="#CCCCCC" />
                        <path id="Vector_31" d="M257.404 295.562C256.788 296.897 255.886 298.08 254.763 299.028C253.64 299.977 252.322 300.667 250.903 301.052C249.484 301.436 247.998 301.504 246.55 301.252C245.101 300.999 243.726 300.432 242.521 299.591L211.391 315.726L212.767 302.02L240.364 284.941C241.912 283.009 244.114 281.713 246.554 281.297C248.994 280.88 251.501 281.374 253.602 282.683C255.702 283.992 257.25 286.026 257.95 288.4C258.651 290.774 258.457 293.322 257.404 295.562H257.404Z" fill="#FFB8B8" />
                        <path id="Vector_32" d="M239.121 305.765L208.775 318.878C206.755 319.744 204.546 320.076 202.361 319.841C200.176 319.607 198.088 318.814 196.298 317.54L157.709 289.891C156.557 289.065 155.587 288.013 154.859 286.797C154.13 285.582 153.659 284.23 153.474 282.826C153.289 281.421 153.394 279.993 153.783 278.631C154.172 277.268 154.837 276 155.735 274.905C157.463 272.822 159.932 271.494 162.622 271.202C165.312 270.909 168.009 271.675 170.144 273.337L203.232 299.379L229.533 289.204L239.121 305.765Z" fill="#CCCCCC" />
                        <path id="Vector_33" d="M151.684 258.585C165.249 258.585 176.245 247.589 176.245 234.024C176.245 220.46 165.249 209.463 151.684 209.463C138.119 209.463 127.123 220.46 127.123 234.024C127.123 247.589 138.119 258.585 151.684 258.585Z" fill="#FFB8B8" />
                        <path id="Vector_34" d="M155.756 241.895C158.786 238.098 164.447 237.894 168.609 235.39C174.061 232.109 176.315 224.779 174.558 218.664C172.801 212.549 167.62 207.783 161.679 205.503C155.739 203.224 149.13 203.196 142.863 204.298C133.533 205.938 124.347 210.293 118.657 217.867C112.966 225.441 111.518 236.533 116.675 244.479C119.046 248.132 122.705 251.146 123.651 255.396C124.893 260.976 120.979 266.357 116.787 270.242C112.01 274.669 106.53 278.376 102.345 283.365C98.1598 288.355 95.3691 295.171 97.2787 301.397C98.8787 306.614 103.535 310.457 108.64 312.381C113.746 314.306 119.306 314.584 124.761 314.691C130.059 314.794 135.685 314.666 140.147 311.809C144.925 308.752 147.475 302.951 147.664 297.282C147.852 291.614 145.958 286.06 143.419 280.988C141.906 277.965 140.146 275.035 139.141 271.808C138.135 268.58 137.961 264.917 139.571 261.945C141.264 258.82 144.689 256.936 148.176 256.251C148.357 256.215 148.548 256.183 148.749 256.155C149.741 256.026 150.697 255.698 151.558 255.189C152.42 254.681 153.169 254.002 153.761 253.196C154.353 252.39 154.775 251.471 155.003 250.497C155.23 249.523 155.257 248.512 155.082 247.527C154.685 245.313 154.719 243.193 155.756 241.895Z" fill="#2F2E41" />
                    </g>
                </g>
                <path id="Vector_35" d="M483.229 567.3H470.969L465.137 520.012L483.231 520.013L483.229 567.3Z" fill="#A0616A" />
                <path id="Vector_36" d="M462.212 563.797H485.856V578.684H447.325C447.325 576.729 447.71 574.793 448.458 572.987C449.206 571.181 450.303 569.539 451.685 568.157C453.068 566.775 454.709 565.678 456.515 564.93C458.321 564.182 460.257 563.797 462.212 563.797Z" fill="#2F2E41" />
                <path id="Vector_37" d="M572.184 553.413L561.033 558.506L536.079 517.917L552.538 510.399L572.184 553.413Z" fill="#A0616A" />
                <path id="Vector_38" d="M551.612 558.958L573.118 549.135L579.303 562.676L544.256 578.685C543.443 576.906 542.989 574.985 542.919 573.032C542.85 571.078 543.165 569.13 543.848 567.298C544.531 565.466 545.568 563.787 546.901 562.356C548.233 560.925 549.834 559.771 551.612 558.958V558.958Z" fill="#2F2E41" />
                <path id="Vector_39" d="M536.482 407.857C537.656 406.973 538.623 405.842 539.313 404.544C540.003 403.246 540.401 401.813 540.477 400.345C540.553 398.877 540.307 397.41 539.755 396.047C539.203 394.685 538.359 393.46 537.283 392.458L551.694 359.759L533.386 362.861L522.507 393.438C520.945 395.358 520.142 397.784 520.249 400.257C520.357 402.73 521.367 405.077 523.09 406.855C524.812 408.632 527.127 409.716 529.595 409.9C532.063 410.085 534.514 409.358 536.482 407.857Z" fill="#A0616A" />
                <path id="Vector_40" d="M482.448 554.831H470.736C469.6 554.827 468.507 554.396 467.675 553.622C466.843 552.849 466.334 551.79 466.247 550.658L459.86 380.099C459.813 379.445 459.909 378.789 460.141 378.177C460.374 377.564 460.737 377.009 461.206 376.552C461.675 376.094 462.239 375.745 462.857 375.527C463.475 375.31 464.133 375.231 464.786 375.294L532.521 382.488C533.618 382.595 534.638 383.102 535.387 383.912C536.136 384.722 536.562 385.778 536.583 386.881L538.002 462.117C538.01 462.525 538.089 462.929 538.236 463.31L566.021 535.363C566.236 535.919 566.338 536.513 566.321 537.109C566.304 537.706 566.168 538.293 565.922 538.836C565.677 539.38 565.325 539.869 564.888 540.275C564.451 540.682 563.938 540.997 563.378 541.203L551.51 545.576C550.479 545.956 549.345 545.946 548.321 545.547C547.298 545.148 546.455 544.389 545.952 543.412L511.82 477.043C511.634 476.682 511.498 476.298 511.416 475.901L505.585 447.807C505.416 446.992 504.962 446.265 504.305 445.754C503.648 445.244 502.831 444.984 502 445.022C501.168 445.06 500.378 445.392 499.77 445.96C499.162 446.527 498.776 447.293 498.681 448.119L486.918 550.843C486.793 551.941 486.268 552.954 485.443 553.69C484.619 554.425 483.552 554.832 482.448 554.831V554.831Z" fill="#2F2E41" />
                <path id="Vector_41" d="M505.123 395.694C488.467 395.511 472.208 390.586 458.25 381.496L458.011 381.331L458.036 381.042L467.431 271.626C467.755 267.913 469.412 264.444 472.095 261.859C474.779 259.274 478.308 257.748 482.03 257.564C494.206 257.004 510.561 257.71 519.316 264.231C532.546 274.085 538.923 288.924 536.811 304.943C532.431 338.162 537.393 382.972 537.973 387.972L538.015 388.333L537.686 388.484C527.486 393.229 516.373 395.69 505.123 395.694V395.694Z" fill="#6C63FF" />
                <path id="Vector_42" d="M537.86 388.158C537.168 388.156 536.483 388.012 535.849 387.733C535.216 387.453 534.647 387.046 534.179 386.536L528.807 380.674C528.226 380.037 527.819 379.262 527.625 378.422C527.431 377.582 527.456 376.707 527.697 375.879L539.948 334.416L521.308 295.272C520.438 293.44 520.225 291.364 520.705 289.393C521.128 287.662 522.065 286.101 523.393 284.915C524.722 283.728 526.379 282.972 528.145 282.747C529.912 282.521 531.706 282.836 533.29 283.65C534.874 284.465 536.173 285.74 537.018 287.309L562.312 328.351C563.34 330.016 563.943 331.907 564.069 333.86C564.194 335.812 563.838 337.766 563.033 339.549L542.423 385.214C542.083 385.969 541.561 386.627 540.904 387.13C540.246 387.633 539.474 387.964 538.656 388.094C538.393 388.136 538.127 388.157 537.86 388.158V388.158Z" fill="#6C63FF" />
                <g id="menHand">
                    <path id="Vector_43" d="M364.738 308.953C365.917 309.83 367.275 310.438 368.715 310.734C370.155 311.03 371.642 311.006 373.072 310.665C374.502 310.324 375.84 309.674 376.991 308.76C378.143 307.846 379.08 306.69 379.736 305.375L415.174 309.971L407.03 293.283L374.626 291.477C372.343 290.52 369.789 290.434 367.447 291.235C365.105 292.036 363.139 293.668 361.92 295.823C360.701 297.977 360.315 300.503 360.834 302.923C361.353 305.343 362.742 307.488 364.738 308.953V308.953Z" fill="#A0616A" />
                    <path id="Vector_44" d="M442.849 318.067C442.232 318.067 441.616 318.024 441.005 317.939L385.254 310.207C384.343 310.082 383.477 309.732 382.735 309.189C381.993 308.646 381.398 307.926 381.003 307.096C380.875 306.828 380.769 306.551 380.685 306.267H380.685C380.469 305.528 380.409 304.753 380.508 303.991C380.607 303.228 380.863 302.494 381.26 301.835L385.873 294.186C386.37 293.369 387.068 292.692 387.9 292.222C388.733 291.752 389.673 291.503 390.629 291.5H390.637L439.233 291.556L475.527 259.039C477.215 257.529 479.372 256.647 481.634 256.539C483.62 256.447 485.588 256.959 487.277 258.008C488.966 259.057 490.298 260.593 491.096 262.414C491.895 264.235 492.122 266.256 491.749 268.209C491.376 270.162 490.42 271.956 489.006 273.355L452.791 313.633C451.538 315.028 450.005 316.144 448.292 316.908C446.579 317.672 444.725 318.067 442.849 318.067V318.067Z" fill="#6C63FF" />
                </g>
                <g id="menHead">
                    <path id="Vector_45" d="M488.279 246.739C501.844 246.739 512.84 235.743 512.84 222.178C512.84 208.613 501.844 197.617 488.279 197.617C474.715 197.617 463.718 208.613 463.718 222.178C463.718 235.743 474.715 246.739 488.279 246.739Z" fill="#A0616A" />
                    <path id="Vector_46" d="M491.072 244.028C493.316 238.833 495.215 231.915 490.91 228.243C488.413 226.113 484.817 226.108 481.545 225.843C472.328 225.096 462.839 220.855 458.334 212.78C453.829 204.704 456.345 192.695 464.844 189.052C470.574 186.596 477.142 188.269 483.139 189.969L501.145 195.075C506.495 196.592 512.112 198.265 515.964 202.277C522.106 208.674 521.647 219.488 516.768 226.895C511.889 234.301 504.542 242.025 496.131 244.837L491.072 244.028Z" fill="#2F2E41" />
                </g>
                <path id="Vector_47" d="M996.391 579H1.60899C1.39791 579 1.1888 578.975 0.993614 578.925C0.798429 578.875 0.621029 578.801 0.471559 578.708C0.32209 578.615 0.203494 578.505 0.122577 578.383C0.0416607 578.262 0 578.132 0 578C0 577.868 0.0416607 577.738 0.122577 577.617C0.203494 577.495 0.32209 577.385 0.471559 577.292C0.621029 577.199 0.798429 577.125 0.993614 577.075C1.1888 577.025 1.39791 577 1.60899 577H996.391C996.602 577 996.811 577.025 997.006 577.075C997.202 577.125 997.379 577.199 997.528 577.292C997.678 577.385 997.797 577.495 997.877 577.617C997.958 577.738 998 577.868 998 578C998 578.132 997.958 578.262 997.877 578.383C997.797 578.505 997.678 578.615 997.528 578.708C997.379 578.801 997.202 578.875 997.006 578.925C996.811 578.975 996.602 579 996.391 579V579Z" fill="#3F3D56" />
            </g>
            <defs>
                <clipPath id="clip0_2_99">
                    <rect width="821.676" height="579.01" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default ScrumBoard
