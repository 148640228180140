
import './style/main.css';
import './style/main.css.map'
import './style/App.css'
import './style/second.css'
import Home from './Components/Home.js'
import Nav from './Components/Nav.js'
// import Skills from './Components/Skills.js'
import ContactMe from './Components/ContactMe.js'
import WorkExperience from './Components/WorkExperience.js'
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
function App() {

  return (
    <>
      <ToastContainer />
      <Nav />
      <div className="container">
        <Home />
        <ContactMe />
        <WorkExperience />
        {/* <Skills /> */}
      </div>
    </>
  );
}

export default App;
