import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import '../../style/LastMile/lastMileLogin.css'
function Verification() {
    // eslint-disable-next-line
    const [code,setCode] = useState()

    const navigate = useNavigate()
    const handleVerify =(e)=>{
        e.preventDefault()
        // eslint-disable-next-line
        const verifyCode = "180360"
        // eslint-disable-next-line
        if(code===verifyCode){
            navigate("/LastMile") 
        }
        else {
            toast.error("Enter Code is Incorrect!")
        }
    }
  return (
    <div className='container'>

    <div className='verification'>
        <h1>Verification Your Phone</h1>
        <p>please type your code, you just received in text </p>
        <input type="text" name="code" value={code} onChange={(e)=>setCode(e.target.value)}/>
        <div>
        <button onClick={handleVerify}>Verify</button> 
        <button>Resend</button>
        </div>
         <ToastContainer />
    </div>
    </div>
  )
}

export default Verification