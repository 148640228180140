import React from 'react'

function SAlogoSvg() {
    return (
        <svg viewBox="0 0 331 333" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SALOGO">
                <g id="BackGround">
                    <path id="Rectangle 1" d="M0 166.5C0 75.0969 74.0969 0 165.5 0V0C256.903 0 331 75.0969 331 166.5V166.5C331 257.903 256.903 333 165.5 333V333C74.0969 333 0 257.903 0 166.5V166.5Z" fill="white" />
                </g>
                <g id="A">
                    <path id="Polygon 1" d="M174.125 34.9401L165.5 20.2367L156.875 34.9401L47.3224 221.69L38.4879 236.75H55.9478H275.052H292.512L283.678 221.69L174.125 34.9401Z" fill="white" stroke="black" stroke-width="20" />
                </g>
                <g id="S">
                    <rect id="Rectangle 2" x="166" y="50" width="48" height="21" fill="white" />
                    <rect id="Rectangle 4" x="84" y="157" width="165" height="21" fill="black" />
                    <rect id="Rectangle 3" x="39" y="178" width="67" height="21" fill="white" />
                </g>
            </g>
        </svg>

    )
}

export default SAlogoSvg
